import { CountUp } from 'countup.js';
import { gsap  } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
//import anime from 'animejs/lib/anime.es.js';

document.addEventListener('DOMContentLoaded', () => {
    gsap.registerPlugin(ScrollTrigger);
    
    window.anims = () => {       
        
        if (document.querySelector('.c-hero')) { 

            const textWrapper1 = document.querySelector('.c-hero .char1');
            textWrapper1.innerHTML = textWrapper1.textContent.replace(/\S/g, "<span class='char'>$&</span>");
            
            const textWrapper2 = document.querySelector('.c-hero .char2');
            textWrapper2.innerHTML = textWrapper2.textContent.replace(/\S/g, "<span class='char'>$&</span>");
          
            textWrapper1.style.visibility = 'visible';
            textWrapper2.style.visibility = 'visible';
            
            let chars1 = textWrapper1.querySelectorAll('.char')
            let chars2 = textWrapper2.querySelectorAll('.char')
        
            let tl = gsap.timeline();
            
            const cfrom = {
                y: -20,
                scaleX: -1,
                opacity: 0,
            }
            
            const cto = {
                y: 0,
                opacity: 1,
                stagger: 0.05,
                duration: .8,
                scaleX: 1,
                ease: 'elastic.out(1, .8)',
            }
            
            tl
            .fromTo(
                chars1, cfrom, cto, 
            )
            
            .fromTo(
                chars2, cfrom, cto, '-=1'
            )
            
            .from(document.querySelector('.c-hero .o-bg'), {
                duration: 1.5,
                x: 40,
                autoAlpha: 0,
                ease: 'elastic.out(1, .8)',
            }, '-=1.4')
            
            .from(document.querySelector(".c-hero .o-drug"), {
                duration: 1.6,
                y: 40,
                autoAlpha: 0,
                ease: 'elastic.out(1, .8)',
            }, '-=1')

            .from(document.querySelector(".c-hero h2 span:nth-of-type(1)"), {
                duration: 1.2,
                y: 40,
                autoAlpha: 0,
                ease: 'elastic.out(1, .8)',
            }, '-=1')
            
            .from(document.querySelector(".c-hero h2 span:nth-of-type(2)"), {
                duration: 1.3,
                y: -30,
                autoAlpha: 0,
                ease: 'elastic.out(1, .8)',
            }, '-=1')
            
            .from(document.querySelector(".c-hero h2 span:nth-of-type(3)"), {
                duration: 1.4,
                y: 20,
                autoAlpha: 0,
                ease: 'elastic.out(1, .8)',
            }, '-=1')
            
            .from(document.querySelector(".c-hero .o-more"), {
                duration: 1.5,
                y: 40,
                autoAlpha: 0,
                ease: 'elastic.out(1, .8)',
            }, '-=2')

            gsap.to(document.querySelector('.c-hero .o-bg'), {
                scrollTrigger: {
                    trigger: document.querySelector('.c-hero .o-bg'),
                    scrub: 2,
                    start: 'top top',
                    toggleActions: "play complete complete repeat",
                },
                yPercent: 10
            });
        }
     
        gsap.utils.toArray(".a-fadeInChildren > *").forEach(function(section) {
            gsap.from(section, {
                scrollTrigger: {
                    trigger: section,
                    start: '-50px bottom',
                    toggleActions: "play complete complete reset",
                },
                opacity: 0, 
                duration: 1,
                y: 50
            });
        });

        if (document.querySelector('.c-who__spec')) {
            ScrollTrigger.batch(".c-who__spec li", {
                onEnter: elements => {
                    gsap.from(elements, {
                        autoAlpha: 0,
                        y: 30,
                        stagger: 0.25,
                        scale: .9,
                    });
                },
                once: true
            });
        };

        const countup = function() {
            const count = document.querySelectorAll('.js-countup');

            ScrollTrigger.create({
                trigger: ".c-who__countup",
                start: "top bottom",
                onEnter: function() {
                    for (let i = 0; i < count.length; i++) {
                        let demo = new CountUp(count[i], count[i].getAttribute('data-count'), { duration: 3 });
                        !demo.error ? demo.start() : false;                        
                    }
                },
                once: true
            });
        };
        
        const what = ()=> {
            ScrollTrigger.matchMedia({
                "(min-width: 1025px)": ()=> {
                    ScrollTrigger.create({
                        trigger: '.article',
                        pin: true,
                        start: 'top top',
                        end: 'bottom bottom'
                    });
                }
            })
        }
        
/*
        const what = ()=> {
            ScrollTrigger.matchMedia({
                "(min-width: 1025px)": ()=> {
                    ScrollTrigger.create({
                        trigger: '.article',
                        pin: true,
                        start: () => 'top ' +  document.querySelector('.c-topbar').offsetHeight + 'px',
                        end: () => "+=" + (document.querySelector('.o-grid--02').offsetHeight - document.querySelector('.o-grid--01').offsetHeight),
                    });
                    
                    ScrollTrigger.create({
                        trigger: '.o-grid--01',
                        pin: true,
                        start: 'bottom bottom',
                        end: () => "+=" + (document.querySelector('.o-grid--02').offsetHeight - document.querySelector('.o-grid--01').offsetHeight),
                    });
                }
            })
        }
*/
        
        
        document.querySelector('.c-who__countup') ? countup() : false;
        document.querySelector('.c-what') ? what() : false;
    };

}, false)
