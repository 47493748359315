//import smoothscroll from 'smoothscroll-polyfill';
//smoothscroll.polyfill();
import { gsap } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";

document.addEventListener('DOMContentLoaded',function() {
    gsap.registerPlugin(ScrollToPlugin);
    
    window.runScroll = function(el, o) {
        
        let offsetTop = document.querySelector(el).offsetTop;
        let topbar_height = document.querySelector('.c-topbar').clientHeight;

        o === undefined ? o = 0 : false;
        
        gsap.to(window, 1, { 
            scrollTo: { 
                y: el,
                offsetY: o + topbar_height,
            }, onComplete: function() {
                console.log('scrolled')
            }
        });
        
        if (document.documentElement.classList.contains('mobile-menu')) {
            window.hideMenu();
        }

/*
        scroll({
            top: offsetTop-topbar_height - o,
            behavior: "smooth"
        })
*/
    };

    const gtt = document.querySelectorAll("[data-target]");
    const privacyindex = document.getElementsByClassName('js-privacyindex')[0];

    if (gtt.length > 0) {
        const action = (e) => {
        	e.preventDefault() ? e.preventDefault() : e.preventDefault = false;
            let target = e.currentTarget.dataset.target,
                offset = e.currentTarget.dataset.offset,
                url = e.currentTarget.href;

            document.querySelector(target) ? window.runScroll(target, offset) : window.open(url + target, '_self');;
        };

        for (let i = 0; i < gtt.length; i++) {
            gtt[i].addEventListener('click', action);
        }
    }
    
}, false);
