document.addEventListener('DOMContentLoaded', () => {

    const cover = document.getElementById('cover');
    
    const init = () => {
        document.documentElement.removeAttribute('style');
        document.documentElement.classList.add('is-loaded');
        
        setTimeout(() => {
            window.anims();
            cover.remove();
            window.location.hash ? window.runScroll( '#'+document.getElementById(window.location.hash.substring(1)).id ) : false;
        }, 500);  
    };
    
    window.addEventListener('load', init);

}, false);